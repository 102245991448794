import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '19, 64, 148',
		'primary-dark': '0, 42, 119',
		'accent': '255, 214, 0',
		'accent-plus': '0, 0, 0',
	},
});
